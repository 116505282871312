import { render, staticRenderFns } from "./CandidatesFilter.vue?vue&type=template&id=0b9f2cd4&scoped=true"
import script from "./candidatesFilter.ts?vue&type=script&lang=ts&external"
export * from "./candidatesFilter.ts?vue&type=script&lang=ts&external"
import style0 from "./CandidatesFilter.vue?vue&type=style&index=0&id=0b9f2cd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9f2cd4",
  null
  
)

export default component.exports