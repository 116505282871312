import { render, staticRenderFns } from "./SLabel.vue?vue&type=template&id=0a270a6b&scoped=true"
import script from "./sLabel.ts?vue&type=script&lang=ts&external"
export * from "./sLabel.ts?vue&type=script&lang=ts&external"
import style0 from "./SLabel.vue?vue&type=style&index=0&id=0a270a6b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a270a6b",
  null
  
)

export default component.exports