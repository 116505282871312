var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SModalCentered", {
    attrs: {
      title: _vm.$t("sModalTeamRequestConfirmation.title"),
      isOpen: _vm.isOpen,
      closeModal: _vm.onClose,
      customProps: { bodyPaddingY: 0 },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "mt-2" }, [
              _c("span", {
                staticClass: "modal-span text-subtitle-5",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("sModalTeamRequestConfirmation.label.name")
                  ),
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.teamRequestNameRef,
                    expression: "teamRequestNameRef",
                  },
                ],
                staticClass: "input-form-stemdo mt-2",
                attrs: {
                  id: "teamRequestName",
                  type: "text",
                  placeholder: _vm
                    .$t("sModalTeamRequestConfirmation.placeholder.name")
                    .toString(),
                },
                domProps: { value: _vm.teamRequestNameRef },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.teamRequestNameRef = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "date-container mt-4" }, [
              _c(
                "div",
                { staticClass: "dateInputs" },
                [
                  _c("span", { staticClass: "modal-span text-subtitle-5" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("sModalTeamRequestConfirmation.label.initDate")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "return-value": _vm.teamRequest.initDate,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.teamRequest, "initDate", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.teamRequest, "initDate", $event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "no-hover",
                                      attrs: {
                                        type: "text",
                                        readonly: "",
                                        outlined: "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        placeholder: _vm.$t(
                                          "sModalTeamRequestConfirmation.placeholder.initDate"
                                        ),
                                      },
                                      model: {
                                        value: _vm.formattedInitDate,
                                        callback: function ($$v) {
                                          _vm.formattedInitDate = $$v
                                        },
                                        expression: "formattedInitDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.isInitDateCalendarOpen,
                        callback: function ($$v) {
                          _vm.isInitDateCalendarOpen = $$v
                        },
                        expression: "isInitDateCalendarOpen",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c("v-date-picker", {
                        attrs: {
                          min:
                            _vm.teamRequest.initDate ||
                            _vm.adjustDate(new Date().toISOString(), 1),
                          max: _vm.adjustDate(_vm.teamRequest.endDate, -1),
                          "first-day-of-week": 1,
                          locale: "es-ES",
                          scrollable: "",
                          "no-title": "",
                        },
                        on: { input: _vm.closeInitDateCalender },
                        model: {
                          value: _vm.initDateRef,
                          callback: function ($$v) {
                            _vm.initDateRef = $$v
                          },
                          expression: "initDateRef",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isEndDateInputHidden
                ? _c("div", { staticClass: "span-option" }, [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "sModalTeamRequestConfirmation.label.addEndDateBtn"
                          )
                        ),
                      },
                      on: { click: _vm.toggleEndDateInput },
                    }),
                  ])
                : _c(
                    "div",
                    { staticClass: "dateInputs" },
                    [
                      _c("span", {
                        staticClass: "modal-span text-subtitle-5",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "sModalTeamRequestConfirmation.label.endDate"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.teamRequest.endDate,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              return _vm.$set(
                                _vm.teamRequest,
                                "endDate",
                                $event
                              )
                            },
                            "update:return-value": function ($event) {
                              return _vm.$set(
                                _vm.teamRequest,
                                "endDate",
                                $event
                              )
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        staticClass: "no-hover",
                                        attrs: {
                                          type: "text",
                                          readonly: "",
                                          outlined: "",
                                          "prepend-inner-icon": "mdi-calendar",
                                          placeholder: _vm.$t(
                                            "sModalTeamRequestConfirmation.placeholder.endDate"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c("div", {
                                                    staticClass:
                                                      "clear-end-date-btn",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "sModalTeamRequestConfirmation.label.removeEndDateBtn"
                                                        )
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.toggleEndDateInput.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.formattedEndDate,
                                          callback: function ($$v) {
                                            _vm.formattedEndDate = $$v
                                          },
                                          expression: "formattedEndDate",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.isEndDateOpenCalendar,
                            callback: function ($$v) {
                              _vm.isEndDateOpenCalendar = $$v
                            },
                            expression: "isEndDateOpenCalendar",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              scrollable: "",
                              min:
                                _vm.adjustDate(_vm.initDateRef, 1) ||
                                _vm.adjustDate(_vm.teamRequest.initDate, 1) ||
                                _vm.adjustDate(new Date().toISOString(), 1),
                              "first-day-of-week": 1,
                              locale: "es-ES",
                            },
                            on: { input: _vm.closeEndDateCalender },
                            model: {
                              value: _vm.endDateRef,
                              callback: function ($$v) {
                                _vm.endDateRef = $$v
                              },
                              expression: "endDateRef",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "jobDescription mt-3" }, [
              _c("span", {
                staticClass: "modal-span text-subtitle-5",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("sModalTeamRequestConfirmation.label.jobDescription")
                  ),
                },
              }),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.teamRequestDescriptionRef,
                    expression: "teamRequestDescriptionRef",
                  },
                ],
                staticClass: "input-form-stemdo mt-2",
                staticStyle: {
                  "min-height": "135px",
                  "max-height": "135px",
                  "flex-shrink": "0",
                  resize: "none",
                },
                attrs: {
                  id: "teamRequestDescription",
                  placeholder: _vm
                    .$t(
                      "sModalTeamRequestConfirmation.placeholder.jobDescription"
                    )
                    .toString(),
                },
                domProps: { value: _vm.teamRequestDescriptionRef },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.teamRequestDescriptionRef = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-6" },
              [
                _c("SButton", {
                  attrs: {
                    disabled: _vm.isButtonDisabled,
                    btnStyle: "primary",
                    action: _vm.onClick,
                    sText: _vm.$t(
                      "sModalTeamRequestConfirmation.label.confirmBtn"
                    ),
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }