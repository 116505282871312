<template>
  <Explore :teamRequestId="teamRequestId" />
</template>

<script setup>
import Explore from '@/sections/shared/explore/Explore.vue';
import { useRouter } from '@/plugins/router';

const router = useRouter();
const teamRequestId = router.currentRoute.query['tr']?.toString();
router.replace({ query: { ...router.currentRoute.query, tr: undefined } });
</script>
