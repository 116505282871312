import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';

export default {
  props: {
    title: {
      type: String,
    },
  },
  components: {
    SButtonBack,
  },
  setup() {
    return {};
  },
};
