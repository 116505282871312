import { render, staticRenderFns } from "./ribbon.vue?vue&type=template&id=523103be&scoped=true"
import script from "./ribbon.component.ts?vue&type=script&lang=ts&external"
export * from "./ribbon.component.ts?vue&type=script&lang=ts&external"
import style0 from "./ribbon.vue?vue&type=style&index=0&id=523103be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523103be",
  null
  
)

export default component.exports