import { computed, ref } from 'vue';
import { useStore } from '@/plugins/vuex';

export default {
  props: {
    teamRequests: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const loggedClientId = store.getters['client'].id;
    const isTeamRequestsEmpty = ref(false);

    const updateRequests = () => {
      const nonMatchingRequests = props.teamRequests.filter(request => request.clientId !== loggedClientId);
      isTeamRequestsEmpty.value = nonMatchingRequests.length === 0;
    };

    updateRequests();

    return {
      isTeamRequestsEmpty,
    };
  },
};
